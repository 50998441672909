import React from 'react';
import styles from './BorderBottom.module.css';

export function BorderBottom() {

  return (
    <div className={styles.border} />
  );

}

BorderBottom.propTypes = {};

BorderBottom.defaultProps = {};
