import React from 'react';
import { Clickable } from 'phoenix-components';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { downChevIcon, upChevIcon } from 'components/shared/Icons/Shop';
import { bankIcon, cashIcon, onlineIcon, } from 'components/shared/Icons/StoreInfo';
import styles from './Payment.module.css';
import { useLanguageLoader } from 'contexts/language';
import { useHashOpenState } from 'hooks/scroll';
import ShopUtils from "../../ui/lib/shopUtils";

const amex = '/images/paymentPartners/amex.svg';
const visa = '/images/paymentPartners/visa.svg';
const maestro = '/images/paymentPartners/maestro.svg';
const gpay = '/images/paymentPartners/gpay.svg';
const paytm = '/images/paymentPartners/paytm.svg';
const paypal = '/images/paymentPartners/paypal.svg';
const upi = '/images/paymentPartners/upi.svg';

function Payment({ shop }) {

  const [show, setShow] = useHashOpenState('shopPayments');
  const onlineEnabled = shop?.paymentModes?.online?.enabled;
  const bankEnabled = shop?.paymentModes?.custompayment?.enabled;
  const cashEnabled = shop?.paymentModes?.cod?.enabled;
  const currCountry = shop?.country;
  const lang = useLanguageLoader();

  const availableOnlinePaymentMethods = [paypal, maestro, amex, visa, gpay];

  if (currCountry === 'india') {
    availableOnlinePaymentMethods.push(paytm, upi);
  }

  const feedTitle = ShopUtils.getTitle(shop, 'paymentMethods');
  const savedTitle = lang('store.menu.paymentMethod') === feedTitle ?
    lang('store.menu.paymentMethod') : feedTitle;

  return (
    <div className={cx(styles.section)} id={'shopPayments'}>
      <Clickable
        className={show ? styles.head : styles.head2}
        onClick={() => setShow(!show)}
      >
        <span></span>
        <span>{savedTitle}</span>
        <span className={styles.icon}>
          {show ? upChevIcon() : downChevIcon()}
        </span>
      </Clickable>
      {show && (
        <div className={styles.body}>
          {onlineEnabled && (
            <div className={styles.grid}>
              <div className="center">
                <img src={onlineIcon()} alt="" className={styles.icon1}/>
                {lang('store.menu.online')}
              </div>
              <div className={styles.partners}>
                {availableOnlinePaymentMethods
                  .map((x, i) =>
                    <img key={i} className={styles.card1} src={x} alt=""/>
                  )}
              </div>
            </div>
          )}
          {bankEnabled && (
            <div className={styles.grid}>
              <div className={styles.flex}>
                <span className={styles.margin}>{bankIcon()}</span>
                {shop?.paymentModes?.custompayment.configured.map((s, i) => {

                  if (s.status) {

                    return (
                      <div className={styles.bank}>
                        {i !== 0 && ','}
                        {s.mode}
                      </div>
                    );

                  }

                })}
              </div>
            </div>
          )}
          {cashEnabled && (
            <div className={styles.grid}>
              <div className="center">
                <img src={cashIcon()} alt="" className={styles.icon1}/>
                {lang('store.menu.cash')}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );

}

Payment.propTypes = {
  shop: PropTypes.object.isRequired,
};

export default Payment;
