import React from "react";
import { Clickable } from "phoenix-components";
import PropTypes from "prop-types";
import cx from "classnames";
// import { contact } from 'ui/api/products';
// import { contactChevIcon } from 'components/shared/Icons/Shop';
import { downChevIcon, upChevIcon } from "components/shared/Icons/Shop";
import styles from "./Terms.module.css";
import { useLanguageLoader } from "contexts/language";
import { useHashOpenState } from "hooks/scroll";
import ShopUtils from "../../ui/lib/shopUtils";

function Terms({ tncs, shop }) {
  const lang = useLanguageLoader();
  const [show, setShow] = useHashOpenState("shopTnc");
  const feedTitle = ShopUtils.getTitle(shop, "shopPolicies");

  return (
    tncs.length > 0 && (
      <div className={cx(styles.section)} id={"shopTnc"}>
        <Clickable
          className={show ? styles.head : styles.head2}
          onClick={() => setShow(!show)}
        >
          <span></span>
          <span>
            {feedTitle === "Shop Policies" ? (
              <>{lang("store.menu.termsPolicies")}</>
            ) : (
              <>{feedTitle}</>
            )}
          </span>
          <span className={styles.icon}>
            {show ? upChevIcon() : downChevIcon()}
          </span>
        </Clickable>
        {show && (
          <div className={styles.body}>
            {tncs.map((s, i) => (
              <div key={i} dangerouslySetInnerHTML={{ __html: s }}></div>
            ))}
          </div>
        )}
      </div>
    )
  );
}

Terms.propTypes = {
  tncs: PropTypes.array,
  shop: PropTypes.object.isRequired,
};

Terms.defaultProps = {
  tncs: [],
};

export default Terms;
