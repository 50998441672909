import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Grid} from '@mui/material';
import {Clickable} from 'phoenix-components';
import {downChevIcon, upChevIcon} from 'components/shared/Icons/Shop';
import styles from './StoreTimings.module.css';
import { useLanguageLoader } from 'contexts/language';
import {useHashOpenState} from "hooks/scroll";
import dayjs from 'dayjs';
import ShopUtils from "../../ui/lib/shopUtils";


function StoreTimings({timings,shop}) {
  const lang = useLanguageLoader();
  const [show, toggleShow] = useHashOpenState('shopStoreTimings');
  const items = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  const getTime = (day) => {

    const smallCap = day?.toLowerCase();
    if (timings[smallCap]) {

      const exp = 'h:mm A';
      const {opensAt, closesAt, status} = timings[smallCap];
      const opens = dayjs(opensAt, 'HH:mm').format(exp);
      const closes = dayjs(closesAt, 'HH:mm').format(exp);
      if (status === 'live') {

        return opens !== closes ? `${opens} - ${closes}` : '24 Hours';

      }
      return 'Closed';

    }
    return '24 Hours';

  };

  const feedTitle = ShopUtils.getTitle(shop, 'shopTimings');

  return (
    <div className={cx(styles.section)} id="shopStoreTimings">
      <Clickable className={styles.head} onClick={() => toggleShow(!show)}>
        <div className={show ? styles.left2 : styles.left}>
          <span>
            {feedTitle === "Shop Timings" ? (
              <>
                {lang('store.menu.storeTimings')}
              </>
            ) : (
              <>
                {feedTitle}
              </>
            )}
          </span>
        </div>
        <span
          className={styles.right}>
          {show ? upChevIcon() : downChevIcon()}
        </span>
      </Clickable>
      {show && (
        <div className={styles.body}>
          <Grid container spacing={2} className={styles.item}>
            {items.map(x => (
              <>
                <Grid item xs={6} className={styles.day}>{lang(`days.${x}`)}</Grid>
                <Grid item xs={6} className={styles.time}>
                  <div className={styles.time}>{getTime(x)}</div>
                </Grid>
              </>
            ))}
          </Grid>
        </div>
      )}
    </div>
  );

}

StoreTimings.propTypes = {
  timings: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
};

export default StoreTimings;
