import React from 'react';
import PropTypes from 'prop-types';
// import mailIcon from '../../public/images/footerIcons/mailIcon.svg';
// import orderIcon from '../../public/images/footerIcons/order.svg';
// import paymentIcon from '../../public/images/footerIcons/payment.svg';
// import phoneIcon from '../../public/images/footerIcons/phone.svg';
// import shippingIcon from '../../public/images/footerIcons/shipping.svg';
// import addressIcon from '../../public/images/footerIcons/addressIcon.svg';
import {
  addressIcon,
  mailIcon,
  orderIcon,
  paymentIcon,
  phoneIcon,
  shippingIcon,
} from 'components/shared/Icons/StoreInfo';
import { isEmpty } from 'ui/lodash';
import { useLanguageLoader } from 'contexts/language';
import cx from 'classnames';
import ShopUtils from 'ui/lib/shopUtils';
import styles from './StoreInfo.module.css';

function StoreInfo({ footerInfo, shop }) {

  const orderEnabled = (isEmpty(footerInfo?.order) || footerInfo?.order?.enabled);

  const paymentEnabled = (isEmpty(footerInfo?.payment) || footerInfo?.payment?.enabled);

  const shippingEnabled = (isEmpty(footerInfo?.shipping) || footerInfo?.shipping?.enabled);
  const lang = useLanguageLoader();

  const storeInformationEnabled = ShopUtils.isSectionEnabled(shop, 'storeInformation');
  const otherInformationEnabled = ShopUtils.isSectionEnabled(shop, 'otherInformation');
  const feedTitle = ShopUtils.getTitle(shop, 'storeInformation');
  const savedTitle = lang('store.headings.storeInformation') === feedTitle ?
    lang('store.headings.storeInformation') : feedTitle;
  return (
    storeInformationEnabled || otherInformationEnabled ? (
      <div className={styles.mainContainer} id="shopStoreInfo">
        <div className={styles.spacer}>{savedTitle}</div>
        {storeInformationEnabled &&  (footerInfo?.store?.enabled === true) && (
          <div className={styles.preview}>
            {footerInfo?.store?.email?.length > 0 && (
              <>
                <div className={styles.flex}>
                  <span>{mailIcon()}</span>
                  <div className={styles.tile}>{footerInfo?.store?.email}</div>
                </div>
                <div className={styles.lineV1}></div>

              </>
            )}
            {footerInfo?.store?.phone?.length > 0 && (
              <>
                <div className={styles.flex}>
                  <span>{phoneIcon()}</span>
                  <div className={styles.tile}>{footerInfo?.store?.phone}</div>
                </div>
                <div className={styles.lineV1}></div>

              </>
            )}
            {footerInfo?.store?.address?.length > 0 && (
              <div className={styles.flex}>
                <span>{addressIcon()}</span>
                <div
                  dangerouslySetInnerHTML={{ __html: footerInfo?.store?.address }}
                  className={styles.tile2}>
                </div>
              </div>
            )}
          </div>
        )}
        {otherInformationEnabled && (
          <>
            {(orderEnabled || paymentEnabled || shippingEnabled) && (
              <div className={cx(styles.grey, styles.storeButtons)}>
                <div>
                  {shippingEnabled && (
                    <>
                      <div className={styles.box}>
                        <span className={styles.shipDesk}>{shippingIcon()}</span>
                        <div className={styles.desc}>
                          <div
                            className={styles.text}
                          >
                            {footerInfo?.shipping?.title || lang('store.headings.fastShipping')}
                          </div>
                          {footerInfo?.shipping?.subTitle?.length > 0 && (
                            <div className={styles.marginTop}>{footerInfo?.shipping?.subTitle}</div>
                          )}
                        </div>
                      </div>
                      <div className={styles.lineV}></div>
                    </>

                  )}
                  {paymentEnabled && (
                    <>
                      <div className={styles.box}>
                        <span className={styles.payDesk}>{paymentIcon()}</span>
                        <div className={styles.desc}>
                          <div
                            className={styles.text}
                          >
                            {footerInfo?.payment?.title || lang('store.headings.seamlessPayments')}
                          </div>
                          {footerInfo?.payment?.subTitle?.length > 0 && (
                            <div className={styles.marginTop}>{footerInfo?.payment?.subTitle}</div>
                          )}
                        </div>
                      </div>
                      <div className={styles.lineV}></div>
                    </>

                  )}
                  {orderEnabled && (
                    <div className={styles.box}>
                      <span className={styles.orderDesk}>{orderIcon()}</span>
                      <div className={styles.desc}>
                        <div
                          className={styles.text}
                        >
                          {footerInfo?.order?.title || lang('store.headings.orderTracking')}
                        </div>
                        {footerInfo?.order?.subTitle?.length > 0 && (
                          <div className={styles.marginTop}>{footerInfo?.order?.subTitle}</div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    ) : null
  );
}

StoreInfo.propTypes = {
  footerInfo: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
};

export default StoreInfo;
