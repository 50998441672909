import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import { Button } from 'phoenix-components';
import { useLanguageLoader } from 'contexts/language';
import styles from './Alert.module.css';

function Alert({ onClose, text }) {
  const lang = useLanguageLoader();
  return (
    <Dialog
      PaperProps={{
        classes: {
          root: styles.paper,
        }
      }}
      open={true}>
      <div className={styles.title}>{text}</div>
      <div className={styles.buttons}>
        <Button
          label={lang('buttons.okay')}
          className={styles.modalBtn}
          size="large"
          onClick={onClose}
        />
      </div>
    </Dialog>
  );

}

Alert.propTypes = {
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Alert;
