import _, { get } from 'ui/lodash';

function isCheckoutAllowed(shop, type) {
  if (_.isEmpty(shop)) {
    return type !== 'guest';
  }
  return _.get(shop, `checkout.login.${type}.enabled`, false);
}

function getLogo(shop, fallback) {
  return _.get(shop, 'icon[0]', fallback);
}

function isDirectCheckout(shop) {
  return isCheckoutAllowed(shop, 'direct');
}

function isTemplateEnabled(shop) {
  return _.get(shop, 'featureConfig.helloBar', false);
}

function showBanner(shop) {
  return _.get(shop, 'featureConfig.banner', true);
}

function isSectionEnabled(shop, name) {
  return get(shop, `uiConfig.layoutConfig[${name}].enabled`, true);
}

// function isBannersAvailable(shop, name) {
//   const banners = get(shop, `uiConfig.layoutConfig[${name}].banners`, []);
//   return banners.length > 0;
// }
function getTestimonials(shop,name) {
  return get(shop, `uiConfig[${name}]`, true);
}

function isAreasServed(shop) {
  // update chargeype to chargeType
  const type = get(shop, 'delivery.chargeType', 'area');
  return type === 'area';
}

function socialMediaEnabled(shop) {
  return get(shop, `socialMedia`, true)
}

function getTitle(shop, title) {
  return get(shop, `uiConfig.layoutConfig[${title}].name`, '');
}

const ShopUtils = {
  isCheckoutAllowed,
  getLogo,
  isDirectCheckout,
  isTemplateEnabled,
  showBanner,
  isSectionEnabled,
  // isBannersAvailable,
  getTestimonials,
  isAreasServed,
  getTitle,
  socialMediaEnabled,
};

export default ShopUtils;
