import React from 'react';
// import cx from 'classnames';
import styles from './CheckoutFooter.module.css';
import PropTypes from 'prop-types';
import { useLanguageLoader } from 'contexts/language';
import { useLocalCart } from 'contexts/cart';
import {sumBy} from 'ui/lodash'
import { getCurrency } from 'ui/utils';
import { useIsCustomDomain } from 'contexts/profile';
import { useRouter } from 'next/router';
import { useQueryParams } from 'hooks/location';
import {Clickable} from 'phoenix-components'

function CheckoutFooter({shop}) {

  const lang = useLanguageLoader();
  const cartData = useLocalCart();
  const productsCount = sumBy(cartData?.products || [], 'quantity');
  const breakUp = cartData?.breakup;
  const isCustomDomain = useIsCustomDomain();
  const router = useRouter();
  const params = useQueryParams();
  const showChat = params.has('openChat');
  const showMenu = params.has('openMenu');

  return (
    <>
      {productsCount > 0 && !showChat && !showMenu && (
        <>
          <div className={styles.padding} />
          <div className={styles.stickyBottom}>
            <div className={styles.div1}>
              {productsCount}
              {' '}
              {productsCount > 1 ? lang('store.words.items') : lang('store.words.item')}
              <div><img className={styles.line2} src="/images/cart/divider.svg" alt=""/></div>
              {getCurrency(breakUp?.total, shop?.currency)}
            </div>
            <Clickable
              className={styles.div2}
              onClick={() => {
                router.push({
                  pathname: isCustomDomain ? '/cart' : `/${shop?.slug}/cart`,
                });
              }}
            >
              {lang('store.words.viewCart')}
            </Clickable>
          </div>
        </>
      )}
    </>
  );

}

CheckoutFooter.propTypes = {
  shop: PropTypes.object.isRequired,
};


export default CheckoutFooter;
