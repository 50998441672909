import React, { useState } from 'react';
import { Button, Clickable, ReactInput } from 'phoenix-components';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { SnackBar } from 'ui/services';
import Alert from 'components/alert/Alert';
import { contact } from 'ui/api/products';
import { useLanguageLoader } from 'contexts/language';
import { downChevIcon, upChevIcon } from 'components/shared/Icons/Shop';
import styles from './ContactUs.module.css';

function ContactUs({
  className,
  id
}) {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [modal, showModal] = useState(false);
  const [show, setShow] = useState(false);
  const lang = useLanguageLoader();

  const resetForm = () => {

    setName('');
    setEmail('');
    setPhone('');
    setMessage('');

  };

  return (
    <div className={cx(styles.section, className)} id="contactUs">
      {modal && (
        <Alert
          onClose={() => showModal(false)}
          text="Thanks for sharing your details. We will contact you shortly."
        />
      )}
      <Clickable
        className={show ? styles.head : styles.head2}
        onClick={() => setShow(!show)}
      >
        <span></span>
        <span>{lang('store.menu.contactUs')}</span>
        <span className={styles.right}>
          {show ? upChevIcon() : downChevIcon()}
        </span>
      </Clickable>
      {show && (
        <form
          Validate
          autoComplete="off"
          id="form"
          className={styles.form}
          onSubmit={async (e) => {

            e.preventDefault();
            const payload = {
              name,
              email,
              phone,
              message,
            };
            try {

              await contact(id, payload);
              // setAddress(copy);
              showModal(true);
              resetForm();
              setShow(false);

            } catch (exception) {

              SnackBar.showError(exception);

            }

          }}
        >
          <ReactInput
            inputClass={styles.inputClass}
            value={name}
            setValue={setName}
            required={true}
            errorTextClass={styles.errClass}
            nestedInputClass={styles.nestedInputClass}
            placeholder={lang('store.contact.name.label')}
          />
          <ReactInput
            inputClass={styles.inputClass}
            value={email}
            setValue={setEmail}
            required={true}
            errorTextClass={styles.errClass}
            nestedInputClass={styles.nestedInputClass}
            placeholder={lang('store.contact.email.label')}
          />
          <ReactInput
            inputClass={styles.inputClass}
            value={phone}
            setValue={setPhone}
            required={true}
            errorTextClass={styles.errClass}
            nestedInputClass={styles.nestedInputClass}
            placeholder={lang('store.contact.phone.label')}
          />
          <ReactInput
            type="textarea"
            rows={2}
            required={true}
            inputClass={styles.inputClass}
            nestedInputClass={styles.nestedInputClass}
            value={message}
            errorTextClass={styles.errClass}
            setValue={setMessage}
            placeholder={lang('store.contact.message.placeholder')}
          />
          <div className={styles.flexCenter}>
            <Button
              type="submit"
              size="large"
              label={lang('buttons.submit')}
              onClick={() => {
              }}
            />
          </div>
        </form>
      )}
    </div>
  );

}

ContactUs.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

ContactUs.defaultProps = {
  className: '',
}

export default ContactUs;
