import React from 'react';
import PropTypes from 'prop-types';
import AboutUs from 'components/shop/AboutUs';
import Terms from 'components/shop/Terms';
import StoreTimings from 'components/shop/StoreTimings';
import Payment from 'components/shop/Payment';
import ContactUs from 'components/shop/ContactUs';
import StoreInfo from 'components/shop/StoreInfo';
import FollowUsAndMore from 'components/shop/FollowUsAndMore';
import Banner from 'components/shared/Banner/createdUsing';
import { Container } from 'components/layout/Container';
import { BorderBottom } from 'components/layout/BorderBottom';
import styles from './Footer.module.css';
import CheckoutFooter from 'components/shared/StickyFooter/CheckoutFooter'
import ShopUtils from 'ui/lib/shopUtils';
import Testimonials from 'components/shop/Testimonials';


export default function Footer({ shop }) {
  const regex = /(<([^>]+)>)/ig;
  const isFilledAboutUs = !!shop?.about?.replace(regex, '').length > 0;
  const isTestimonials = shop?.uiConfig?.testimonials?.length > 0;
  const isFilledTerms = shop?.tncs?.length > 0;
  const appStoreLink = 'https://apps.apple.com/in/app/windo-seller/id1559110127';
  const playStoreLink = 'https://play.google.com/store/apps/details?id=live.windo.seller';
  const windoLink = 'https://mywindo.shop/';

  const aboutUsEnabled = ShopUtils.isSectionEnabled(shop, 'aboutUs');
  const shopPoliciesEnabled = ShopUtils.isSectionEnabled(shop, 'shopPolicies');
  const shopTimingsEnabled = ShopUtils.isSectionEnabled(shop, 'shopTimings');
  const paymentMethodsEnabled = ShopUtils.isSectionEnabled(shop, 'paymentMethods');
  const testimonialsEnabled = ShopUtils.isSectionEnabled(shop, 'testimonials');
  return (
    <>
      <div className={styles.footer}>
        <Container>
          <div id="shopScroll10"></div>
          {isTestimonials && testimonialsEnabled && <Testimonials shop={shop} />}
          {aboutUsEnabled && isFilledAboutUs && <AboutUs about={shop?.about} shop={shop} />}
          <div id="shopScroll5"></div>
          {shopPoliciesEnabled && isFilledTerms && <Terms tncs={shop?.tncs} shop={shop} />}
          <div id="shopScroll6"></div>
          {shopTimingsEnabled && <StoreTimings timings={shop?.storeTimings || {}} shop={shop} />}
          <div id="shopScroll7"></div>
          {paymentMethodsEnabled && <Payment shop={shop} />}
          <div id="shopScroll8"></div>
          <ContactUs id={shop?._id} />
          <div id="shopScroll9"></div>
        </Container>
      </div>
      <BorderBottom />
      <div className={styles.footer}>
        <Container>
          <StoreInfo shop={shop} footerInfo={shop?.footerInfo} />
          <FollowUsAndMore shop={shop} />
        </Container>
      </div>
      <Banner shop={shop} />
      <CheckoutFooter
        shop={shop}
      />
      <div className={styles.seoLinks}>
        <a href={appStoreLink} target="_blank" rel="noreferrer">
          <img src="/images/appstore.svg" alt="" />
        </a>
        <div className={styles.spacer}></div>
        <a href={playStoreLink} target="_blank" rel="noreferrer">
          <img src="/images/playstore.svg" alt="" />
        </a>
        <a href={windoLink} target="_blank" rel="noreferrer">
          <img src="/images/logo_png.png" alt="" />
        </a>
      </div>
    </>
  );

}

Footer.propTypes = {
  shop: PropTypes.object.isRequired,
};

Footer.defaultProps = {};
