import React from 'react';
import {Clickable} from 'phoenix-components';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useLanguageLoader } from 'contexts/language';
// import { contact } from 'ui/api/products';
import {downChevIcon, upChevIcon} from 'components/shared/Icons/Shop';
import ShopUtils from 'ui/lib/shopUtils';
import styles from './AboutUs.module.css';
import {useHashOpenState} from "hooks/scroll";

function AboutUs({about, shop}) {
  const lang = useLanguageLoader();
  const [show, setShow] = useHashOpenState('shopAboutUs');
  const feedTitle = ShopUtils.getTitle(shop, 'aboutUs');
  const savedTitle = lang('store.menu.aboutUs') === feedTitle ?
    lang('store.menu.aboutUs') : feedTitle;

  return (
    <div className={cx(styles.section)} id="shopAboutUs">
      <Clickable
        className={show ? styles.head : styles.head2}
        onClick={() => setShow(!show)}
      >
        <span>{savedTitle}</span>
        <span className={styles.icon}>
          {show ? upChevIcon() : downChevIcon()}
        </span>
      </Clickable>
      {show && (
        <div className={styles.body}>
          <div
            dangerouslySetInnerHTML={{__html: about}}
          >
          </div>
        </div>
      )}
    </div>
  );

}

AboutUs.propTypes = {
  about: PropTypes.string.isRequired,
  shop: PropTypes.object.isRequired,
};

export default AboutUs;
