import {useEffect, useState} from "react";
import {useIsDesktop} from "../contexts/profile";
import {useRouter} from "next/router";

export function useHashOpenState(id) {

  const router = useRouter();

  const [show, setShow] = useState(false);

  useEffect(()=>{

    if(document.location.hash === `#${id}`) {

      setShow(true);

    }
  
  },[id, router.asPath]);

  return [show, setShow];

}

export function useScrollToHash() {

  const router = useRouter();

  const isDesktop = useIsDesktop();

  useEffect(()=>{

    let timeout = setTimeout(()=>{

      const hash = document.location.hash.substr(1);
      if(hash.length > 0 ) {

        const ele = document.getElementById(hash);
        if(ele) {

          const offset = isDesktop ? 100: 140;

          const y = ele.offsetTop;
          window.scroll({
            top: y - offset,
          });

        }
        timeout = null;
      
      }

    
    }, 1000)

    return ()=>{

      if(timeout) {

        clearTimeout(timeout);
      
      }
    
    }
  
  },[router.asPath])

}