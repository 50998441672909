import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Clickable } from 'phoenix-components';
import { useLanguageLoader } from 'contexts/language';
import ShopUtils from 'ui/lib/shopUtils';
import cx from 'classnames';
import { useIsCustomDomain } from 'contexts/profile';
import styles from './FollowUsAndMore.module.css';

const ig = '/images/followUs/ig.svg';
const wa = '/images/followUs/wa.svg';
const tw = '/images/followUs/tw.svg';
const fb = '/images/followUs/fb.svg';
const yt = '/images/followUs/yt.svg';
const pi = '/images/followUs/pi.svg';

function FollowUsAndMore({ shop, variant }) {

  const router = useRouter();

  const isCustomDomain = useIsCustomDomain();
  const socialMediaEnabled = ShopUtils.isSectionEnabled(shop, 'socialMedia');
  const ppTncsEnabled = ShopUtils.isSectionEnabled(shop, 'ppTncs');
  const tncsEnabled = ShopUtils.isSectionEnabled(shop, 'tncs');
  const onClick = (isPolicy = true) => {
    if (isPolicy) {
      router.push({ pathname: isCustomDomain ? '/privacypolicy' : `/${shop.slug}/privacypolicy` });
      return;
    }
    router.push({ pathname: isCustomDomain ? '/terms' : `/${shop.slug}/terms` });
  }

  const lang = useLanguageLoader();
  const mapper = {
    instagram: ig,
    whatsapp: wa,
    pinterest: pi,
    twitter: tw,
    youtube: yt,
    facebook: fb,
  };

  function addhttp(item) {

    let { url } = item;
    if (item?.name === 'whatsapp') {

      window.open(`https://api.whatsapp.com/send/?phone=${url}`, '_blank');
      return;

    }
    if (!/^(?:f|ht)tps?\/\//.test(url)) {

      url = `${url}`;

    }
    window.open(url, '_blank');

  }

  const links = shop?.linkedAccounts || [];

  const feedTitle = ShopUtils.getTitle(shop, 'ppTncs');
  const savedTitle = "Privacy Policy" === feedTitle ?
    "Privacy Policy" : feedTitle;
  const feedTitleTncs = ShopUtils.getTitle(shop, 'tncs');
  const savedTitleTncs = "Terms & Conditions" === feedTitleTncs ?
    "Terms & Conditions" : feedTitleTncs;


  return (
    <div className={cx(styles.section, styles[variant])}>
      {socialMediaEnabled && links.length > 0 && (
        <>
          <div className={styles.head}>{lang('store.menu.followUsOn')}</div>
          <div className={styles.follow}>
            {links.map((x, i) => (
              <Clickable
                key={i}
                className={styles.card}
                onClick={() => addhttp(x)}
              >
                <img src={mapper[x.name]} alt="" />
              </Clickable>
            ))}
          </div>
        </>
      )}

      <div className={styles.termsAndPolicy}>
        {ppTncsEnabled && variant === 'complete' && (
          <Clickable
            onClick={() => onClick(true)}
            className={styles.link}>
            {savedTitle}
          </Clickable>
        )}
        {tncsEnabled && variant === 'complete' && (
          <>
            <div className={styles.seperator}> - </div>
            <Clickable
              onClick={() => onClick(false)}
              className={styles.link}>
              {savedTitleTncs}
            </Clickable>
          </>
        )}
      </div>

    </div>
  );

}

FollowUsAndMore.propTypes = {
  shop: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

FollowUsAndMore.defaultProps = {
  variant: 'complete',
};

export default FollowUsAndMore;
