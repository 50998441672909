import React, { useState } from 'react';
import { Clickable } from "phoenix-components";
import { downChevIcon, upChevIcon, locationIcon, defaultPpIcon } from 'components/shared/Icons/Shop';
import { useHashOpenState } from 'hooks/scroll';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { exIcon } from 'components/shared/Icons/Product';
import { useIsDesktop } from 'contexts/profile';
import ShopUtils from 'ui/lib/shopUtils';
import styles from './Testimonials.module.css'
// import { useLanguageLoader } from 'contexts/language';
import { useKeenSlider } from 'keen-slider/react';

export default function Testimonials({ shop }) {
  const [show, setShow] = useHashOpenState('shopTestimonials');
  const isDesktop = useIsDesktop();
  const data = ShopUtils.getTestimonials(shop, 'testimonials');
  const [,setCurrentSlide] = useState(0);
  // const lang = useLanguageLoader();
  const perView = isDesktop ? 2.4 : 1;

  const [sliderRef] = useKeenSlider(
    {
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      slides: {
        perView: perView,
        spacing: 10,
      }
    },
  );

  const feedTitle = ShopUtils.getTitle(shop, 'testimonials');
  const savedTitle = "Testimonials" === feedTitle ?
    "Customer Testimonials" : feedTitle;
  return (<div className={cx(styles.section)} id="shopTestimonials">
    <Clickable
      className={show ? styles.head : styles.head2}
      onClick={() => setShow(!show)}
    >
      <span></span>
      <span>{savedTitle}</span>
      <span className={styles.icon}>
        {show ? upChevIcon() : downChevIcon()}
      </span>
    </Clickable>
    {show && <div>
      <div
        ref={sliderRef}
        className={'keen-slider'}
      >
        {data.map((el, i) => (
          <>
            {el.enabled &&
              <div
                key={i}
                className={cx({ [styles.single]: Testimonials.length === 1 }, 'keen-slider__slide')}
              >
                <div className={styles.testimonialContainer}>
                  <div className={styles.section1}>
                    {el.customerImage === '' ? defaultPpIcon() :
                      <img src={el.customerImage} alt="" className={styles.profilePic} />
                    }
                    <div className={styles.ex}>{exIcon()}</div>
                  </div>
                  <div className={styles.name}>{el.name}
                    {el.designation !== '' &&
                      <span className={styles.des}>({el.designation})</span>
                    }</div>
                  {el.location !== '' &&
                    <div className={styles.location}>{locationIcon()}
                      <div className={styles.locationName}>{el.location}</div>
                    </div>}
                  {el.type === 'message' ?
                    <div className={styles.message}>{el.message}</div>
                    : <div className={styles.imageCon}>
                      <img src={el.testimonialImage} alt="" className={styles.testimonialImage} />
                    </div>}
                </div>
              </div>
            }
          </>
        ))}
      </div>

    </div>}
  </div>)
}
Testimonials.propTypes = {
  shop: PropTypes.object.isRequired,
};

Testimonials.defaultProps = {};
