import React from 'react';
import cx from 'classnames';
import styles from './createdUsing.module.css';
import PropTypes from 'prop-types';
import { useLanguageLoader } from 'contexts/language';
import { get } from 'ui/lodash';

const logo = '/images/banner/text.svg';
const love = '/images/banner/myLove.svg';

function CreatedUsing({shop}) {

  const lang = useLanguageLoader();

  const showBanner = !get(shop, 'featureConfig.banner', true);

  return (
    <div className={styles.appSection}>
      <div className={cx('fullHeight hor-center')}>
        {showBanner ? (
          <><span className={cx(styles.text, 'fs12')}>
            made with
            <a href="https://www.getwindo.shop" target="_blank" rel="noreferrer">
              <img className={styles.border1} src={love} alt="" />
            </a>
            using
          </span>
          <a href="https://www.getwindo.shop" target="_blank" rel="noreferrer">
            <img className={styles.border} src={logo} alt="" />
          </a>
          </>
        ) :(
          <span className={cx(styles.text2)}>
            {lang('store.headings.copyRight')} &copy; {shop.name}
          </span>
        )}

      </div>
    </div>
  );

}

CreatedUsing.propTypes = {
  shop: PropTypes.object.isRequired,
};

export default CreatedUsing;
