import axios from './axios';

export const listSavedProducts = async (page = 0) => {
  const { data } = await axios.get('/savedProducts/list', {
    params: {
      page,
    }
  });
  return data;
};

export const listShopProducts = async (id, page = 0, payload) => {
  const { data } = await axios.get('/shop/products', {
    params: {
      page,
      id,
      ...payload,
    }
  });
  return data;
};

export const addLike = async (id) => {
  const { data } = await axios.post('/shop/like', {
    id,
  });
  return data;
};

export const contact = async (id, payload) => {
  const { data } = await axios.post('/shop/contact', {
    id,
    payload
  });
  return data;
};

export const uploadAsset = async (payload, onUploadProgress = () => {}) => {
  const { data } = await axios.post('/shop/uploadAsset', payload, {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onUploadProgress(percentCompleted);
    },
  });
  return data;
};

export const getProductDetails = async (id) => {
  const { data } = await axios.get('/shop/product', {
    params: {
      id
    }
  });
  return data;
};

export const listRatings = async (id, page = 0) => {
  const { data } = await axios.get('/products/listRatings', {
    params: {
      page,
      id,
    }
  });
  return data;
};

export const submitSkuRating = async (id, payload) => {
  const { data } = await axios.post('/products/productRating', {
    id,
    payload,
  });
  return data;
};